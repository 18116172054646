import React, { Component } from 'react';
import { Row, Col, Tooltip} from 'reactstrap';
import { getAccessToken } from '../../utils';
import { gaTelesisInstance } from '../../shared_elements/components';

export default class OperatorLessor extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle() {
   this.setState({
     tooltipOpen: !this.state.tooltipOpen
   });
  }
  render(){
    return(
      <>
        {
          gaTelesisInstance && this.props.obj ? (
            <a style={{display:'grid'}}>
              {this.props.obj.logo != null ?<img className="asset-logo" src={this.props.obj.logo} alt={this.props.obj.name} id={this.props.slug} />:null}
              <span style={{ fontSize: '12px', fontFamily: "Conv_IBMPlexSans-Regular", color: '#000' }}>
                {this.props.obj.name}
              </span>
            </a>
          ) : (
            this.props.obj && this.props.obj.logo != null ? (
              <img className="asset-logo" src={this.props.obj.logo} alt={this.props.obj.name} id={this.props.slug} />
            ) : (
              this.props.obj && this.props.obj.name ? (
                <span style={{ fontSize: '12px', fontFamily: "Conv_IBMPlexSans-Regular", color: '#000' }}>
                  {this.props.obj.name}
                </span>
              ) : (
                '--'
              )
            )
          )
        }

        {
          this.props.obj && this.props.obj.logo ?
          <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={this.props.slug} toggle={this.toggle}>
            {this.props.obj.name}
          </Tooltip>:null
        }

      </>
    )
  }
}
